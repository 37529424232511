'use client';

import {
  Column,
  Form,
  FormState,
  PinInput,
  PinInputProps,
} from '@kamona/components';
import { debounce } from 'lodash';
import { useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { ResendCode } from './resend-code';

interface OTPInputProps {
  onComplete?: (value: string) => void;
  onResend?: () => void;
  enableResendInSeconds?: number;
}

export function OTPInput({
  value,
  length = 6,
  onComplete,
  onResend,
  disabled = false,
  enableResendInSeconds,
  ...props
}: Readonly<OTPInputProps> & PinInputProps) {
  let submitRef = useRef<HTMLFormElement>(null);

  const handleSubmit: SubmitHandler<Record<string, any>> = ({ pinInput }) => {
    onComplete?.(pinInput);
  };

  const onPinInputChange = (value: string, isValidForm: boolean) => {
    if (value.length === length && isValidForm) {
      submitRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  };

  const onPinInputChangeDebounced = debounce((func) => func(), 500);

  return (
    <Column className="gap-y-units-unit16">
      <Form onSubmit={handleSubmit} ref={submitRef}>
        <FormState>
          {(formState) => (
            <PinInput
              name="pinInput"
              autoFocus
              inputMode="numeric"
              length={length}
              maxLength={length}
              variant="filled"
              onChange={(value: string) =>
                onPinInputChangeDebounced(
                  onPinInputChange.bind(this, value, formState.isValid),
                )
              }
              required
              disabled={disabled}
              pattern={{
                message: 'Only numeric values are allowed',
                value: /^\d+$/,
              }}
              {...props}
            />
          )}
        </FormState>
      </Form>

      {onResend && <ResendCode {...{ onResend, enableResendInSeconds }} />}
    </Column>
  );
}
