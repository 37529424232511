'use client';

import {
  Button,
  Column,
  Form,
  FormState,
  PhoneNumberInput,
} from '@kamona/components';

import { countryOptionsForPhoneInput } from '@/ui/components/phone-input/phone-input-data';

import { usePhoneNumberForm } from '../hooks/use-phone-number-form';

export const SigninForm = ({ ...props }) => {
  const { handleSignin, initialValues } = usePhoneNumberForm();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSignin}
      validationMode="onChange"
      className="flex flex-grow justify-start"
    >
      <Column
        className="w-full flex-grow justify-between md:flex-grow-0 gap-y-units-unit40 md:justify-start"
        {...props}
      >
        <Column as="fieldset" gap="units-unit20">
          <PhoneNumberInput
            variant="filled"
            inputMode="tel"
            label="Mobile number"
            defaultCountry="GB"
            name="phoneNumber"
            required={{ value: true, message: 'Phone number is required' }}
            size="large"
            supportedCountries={countryOptionsForPhoneInput}
          />
        </Column>
        <Column gap="units-unit16">
          <FormState>
            {(formState) => {
              return (
                <Button
                  size="medium"
                  type="submit"
                  variant="filled"
                  appearance="primary"
                  disabled={!formState.isValid}
                >
                  Continue
                </Button>
              );
            }}
          </FormState>
        </Column>
      </Column>
    </Form>
  );
};
