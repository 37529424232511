import { useHttpMutation } from '@/shared/hooks';

import { Start2faStepResponse } from '../../../shared/types';

export const useStart2faStep = (
  options?: Parameters<typeof useHttpMutation<void, Start2faStepResponse>>[0],
) => {
  return useHttpMutation<void, Start2faStepResponse>({
    method: 'POST',
    // api: mockApi,
    ...options,
  });
};
