'use client';

import { Flex, FlexProps } from '@/ui/components/flex';
import { Text } from '@/ui/components/text';
import ClockSvg from '@/ui/svgs/clock-svg';

import { logError } from '@/lib/utils';

import { formatTime } from './otp-input-utils';
import { useTimer } from './use-timer-hook';

interface ResendCodeProps extends FlexProps {
  onResend: () => void;
  enableResendInSeconds?: number;
}

export function ResendCode({
  onResend,
  enableResendInSeconds = 60,
  ...props
}: Readonly<ResendCodeProps>) {
  const { timeLeft, setTimeLeft } = useTimer(enableResendInSeconds);

  const handleResend = async () => {
    try {
      onResend();

      // Reset the timer
      setTimeLeft(enableResendInSeconds);
    } catch (error) {
      logError(error);
    }
  };

  return (
    <Flex gap="units-unit4" className="text-body-default " {...props}>
      <ClockSvg />
      <Text weight="semibold">Didn&apos;t get code?</Text>{' '}
      {timeLeft > 0 ? (
        <>
          Resend code in{' '}
          <Text weight="semibold" color="primary-l7">
            {formatTime(timeLeft)}
          </Text>
        </>
      ) : (
        <Text
          as="button"
          weight="semibold"
          color="primary-l7"
          onClick={handleResend}
          aria-label="resend-otp-button"
        >
          Resend
        </Text>
      )}
    </Flex>
  );
}
